@import "../abstracts/variables";
@import "../abstracts/mixins";

.home {
  position: relative;
  width: 100%;
  height: auto;

  &-slider-container {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
  }
}
