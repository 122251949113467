@import "../abstracts/variables";
@import "../abstracts/animations";
@import "../abstracts/mixins";

.contact {
  width: 100%;
  height: auto;
  margin: 11rem auto 0 auto;
  background-color: $back-color-secendary;
  position: relative;

  @include respond(tab-port) {
    margin: 7rem auto 0 auto;
  }

  &-minContainer {
    width: 70%;
    margin: 0 auto;
    padding: 1.5rem;

    @include respond(big-desktop) {
      width: 60%;
    }

    @include respond(larg-desktop) {
      width: 50%;
    }

    @include respond(phone) {
      width: 90%;
    }

    &_line {
      width: 6rem;
      height: 0.4rem;
      background-color: $text-color-orange;
      margin: 3rem 0 0 0rem;
    }

    & h1 {
      font-size: 4.5rem;
      color: $navbar-color;
      font-weight: 700;
      font-family: inherit;
      padding: 1rem 0 2rem 0rem;

      @include respond(phone) {
        font-size: 3.5rem;
        padding: 2rem 0 0 0;
      }
    }
  }

  &-container {
    width: 70%;
    height: 55vh;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0rem 0rem 8rem 0;
    display: flex;
    align-items: center;

    @include respond(big-desktop) {
      width: 60%;
    }

    @include respond(larg-desktop) {
      width: 50%;
    }

    @include respond(tab-land) {
      height: 28vh;
    }

    @include respond(phone) {
      flex-direction: column;
      height: auto;
      width: 90%;
    }

    &-getintouch {
      width: 35%;
      height: 100%;

      @include respond(phone) {
        width: 100%;
        height: auto;
      }

      &_items {
        font-size: 2rem;
        font-family: "Larsseit";
        animation-name: moveInLeft;
        animation-duration: 1.4s;
        animation-timing-function: ease-out;

        &_list {
          padding: 1.5rem;

          &-maildetail {
            padding: 0.5rem;

            & svg {
              color: $text-color-orange;
              font-size: 1.5rem;
            }

            & a {
              text-decoration: none;
              color: $text-color-md-dark;
              margin-left: 1rem;
              font-size: 1.5rem;
              transition: 0.4s ease;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          &-addressdetail {
            padding: 0.5rem;

            & svg {
              color: $text-color-orange;
              font-size: 1.5rem;
            }

            & span {
              color: $text-color-md-dark;
              margin-left: 1rem;
              font-size: 1.5rem;
            }
          }

          &-phonedetail {
            padding: 0.5rem;

            & svg {
              color: $text-color-orange;
              font-size: 1.5rem;
            }

            & a {
              text-decoration: none;
              color: $text-color-md-dark;
              margin-left: 1rem;
              font-size: 1.5rem;
              transition: 0.4s ease;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          &-mail {
            color: $text-color-orange;
            font-weight: 700;
          }
          &-address {
            color: $text-color-orange;
            font-weight: 700;
          }
          &-phone {
            color: $text-color-orange;
            font-weight: 700;
          }
        }
      }
    }

    &-form {
      width: 60%;
      height: 100%;
      animation-name: moveInRight;
      animation-duration: 1.4s;
      animation-timing-function: ease-out;

      @include respond(tab-land) {
        height: 90%;
      }

      @include respond(phone) {
        width: 95%;
        height: 30rem;
      }

      &-input {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        & input {
          width: 32%;
          padding: 1rem;
          border-radius: 0.5rem;
          border: none;
          background-color: $text-color-orange;
          transition: all 0.4s ease;
          outline: none;

          &::-webkit-input-placeholder {
            font-weight: 100;
            color: #ffff;
          }

          &:focus {
            background-color: rgba($text-color-orange, 0.7);
            border-radius: 0.5rem;
            color: #ffff;
            box-shadow: none;
          }
        }
      }

      & form {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        & textarea {
          width: 100%;
          height: 60%;
          padding: 1rem;
          border-radius: 0.5rem;
          border: none;
          background-color: $text-color-orange;
          transition: all 0.4s ease;
          outline: none;

          &::-webkit-input-placeholder {
            font-weight: 100;
            color: #ffff;
          }

          &:focus {
            background-color: rgba($text-color-orange, 0.7);
            border-radius: 0.5rem;
            color: #ffff;
          }
        }

        & button {
          width: 32%;
          padding: 1rem;
          cursor: pointer;
          border: none;
          background-color: $text-color-orange;
          color: #fff;
          border-radius: 0.5rem;
          transition: 0.4s ease;

          @include respond(tab-port) {
            padding: 1rem 1.5rem 1rem 1.5rem;
            font-size: 1.2rem;
          }

          @include respond(small-phone) {
            font-size: 1rem;
          }

          &:focus {
            outline: none;
          }

          &:hover {
            background-color: $text-color-blue_hover;
          }
        }
      }
    }
  }

  &-google_map {
    width: 80%;
    height: 70vh;
    margin: 0 auto;
    padding: 0rem 0rem 5rem 0;

    @include respond(big-desktop) {
      width: 70%;
      height: 80vh;
    }

    @include respond(larg-desktop) {
      width: 60%;
      height: 80vh;
    }

    @include respond(tab-land) {
      height: 35vh;
      width: 70%;
    }

    @include respond(phone) {
      width: 90%;
      height: 50vh;
    }

    & iframe {
      width: 100%;
      height: 100%;
    }
  }
}
