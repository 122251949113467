@import "../abstracts/variables";
@import "../abstracts/mixins";

.scroll-review {
  height: 65vh;
  overflow-y: scroll;
  background-color: #ffff;

  &::-webkit-scrollbar {
    width: 0.3rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $text-color-orange;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $text-color-blue_hover;
    cursor: pointer;
  }
}

.event_reviews {
  margin: 10%;
  width: 50%;

  @include respond(tab-port) {
    width: 90%;
    margin: 10% auto;
  }

  &-title {
    color: $text-color-orange;
    font-family: "Larsseit";
    font-weight: normal;
    font-size: 2rem;
  }

  &-list {
    padding: 3rem 0 3rem 0;
    font-size: 1.3rem;

    &-items {
      width: 50rem;
      padding: 1rem;
      margin: auto 2rem auto 2rem;
      border-bottom: 1px solid #cccc;
      position: relative;

      @include respond(big-desktop) {
        width: 70rem;
      }

      @include respond(larg-desktop) {
        width: 77rem;
      }

      @include respond(tab-port) {
        width: 100%;
        margin: 2rem auto;
      }

      &_user {
        color: $text-color-orange;
      }

      &_date {
        color: $text-color-orange;
        margin-left: 1rem;
      }

      & svg:nth-child(1) {
        position: absolute;
        top: 1.2rem;
        right: 2rem;
        cursor: pointer;
        color: $text-color-orange;

        &:hover {
          color: $text-color-blue_hover;
        }
      }

      & svg:nth-child(2) {
        position: absolute;
        top: 1.2rem;
        right: 0;
        cursor: pointer;
        color: $text-color-orange;

        &:hover {
          color: $text-color-blue_hover;
        }
      }

      &-comment {
        padding: 2rem;
        color: $text-color-md-dark;
        font-family: "Larsseit";
      }
    }
  }

  &-form {
    width: 100%;
    font-size: 1.3rem;

    & form {
      display: flex;
      flex-direction: column;
      width: 50%;

      @include respond(tab-land) {
        width: 70%;
      }

      @include respond(phone) {
        width: 90%;
      }

      & textarea {
        padding: 2rem;
        margin: 2rem 0rem 3rem 0rem;
        border-radius: 0.5rem;
        border: 0.1rem solid $text-color-orange;
        background-color: $text-color-orange;

        @include respond(phone) {
          padding: 4rem 4rem 4rem 2rem;
        }

        &::-webkit-input-placeholder {
          font-weight: 100;
          color: #ffff;
        }

        &:focus {
          background-color: rgba($text-color-orange, 0.7);
          border-radius: 0.5rem;
          border: none;
          outline: none;
          color: #ffff;
        }
      }

      & button {
        width: 50%;
        padding: 0.5rem;
        margin: 1rem 0rem 1rem 0rem;
        cursor: pointer;
        border: none;
        background-color: $text-color-orange;
        color: #fff;
        border-radius: 0.5rem;
        transition: 0.4s ease;

        @include respond(phone) {
          padding: 1rem 1rem 1rem 0rem;
        }

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: $text-color-blue_hover;
        }
      }
    }

    & p {
      margin: 2rem 0rem 2rem 0rem;
      font-size: 1.5rem;
      color: darkorange;

      & a {
        color: darkorange;
      }
    }
  }
}
