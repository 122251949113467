@import "../abstracts/variables";
@import "../abstracts/mixins";

.obstacle-sports-georgia {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;

  @include respond(tab-port) {
    display: block;
  }

  &_left {
    width: 45%;
    height: auto;

    @include respond(tab-port) {
      width: 100%;
    }

    &-line {
      width: 5rem;
      height: 0.4rem;
      background-color: $text-color-orange;
      margin: 7rem 0rem 0rem 3rem;

      @include respond(phone) {
        width: 17%;
        margin: 7rem auto 0 3rem;
      }
    }

    & h1 {
      padding: 3rem 0 3rem 3rem;
      color: $text-color-dark;
      font-size: 5rem;

      @include respond(phone) {
        font-size: 3.5rem;
      }

      @include respond(small-phone) {
        font-size: 3rem;
        text-align: center;
        padding: 2rem;
      }
    }

    & p:nth-child(3) {
      padding: 1rem 0 2rem 3rem;
      font-weight: 300;
      font-family: "Poppins", Helvetica, Arial, sans-serif;
      color: #777;
      line-height: 1.5;
      font-size: 1.8rem;

      @include respond(tab-port) {
        margin: 0 auto;
        width: 90%;
        padding: 1rem 0 3rem 0rem;
      }

      @include respond(phone) {
        text-align: center;
        padding: 1rem 0 3rem 0rem;
      }
    }

    & p:nth-child(4) {
      padding: 1rem 0 10rem 3rem;
      font-weight: 300;
      font-family: "Poppins", Helvetica, Arial, sans-serif;
      color: #777;
      line-height: 1.5;
      font-size: 1.8rem;

      @include respond(tab-port) {
        margin: 0 auto;
        width: 90%;
        padding: 1rem 0 6rem 0rem;
      }

      @include respond(phone) {
        text-align: center;
        padding: 1rem 0 10rem 0rem;
      }
    }
  }

  &_right {
    width: 40%;
    height: auto;
    position: relative;

    @include respond(tab-port) {
      width: 70%;
      height: 35vh;
      margin: 1rem auto;
    }

    @media (max-width: 540px) {
      width: 100%;
      height: 55vh;
    }

    @media (max-width: 411px) {
      height: 30vh;
    }

    &-img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0%, -50%);
      -webkit-transform: translate(0%, -50%);
      -ms-transform: translate(0%, -50%);

      @include respond(phone) {
        top: 30%;
      }
    }
  }
}
