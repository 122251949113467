@import "./variables";
@import "./mixins";

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  &.swiper-pagination-hidden {
    opacity: 0;
  }
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 2%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include respond(tab-land) {
    width: 8%;
  }

  @include respond(tab-port) {
    width: 10%;
  }

  @include respond(phone) {
    width: 20%;
    bottom: 10%;
  }
}
/* Bullets */

.swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50%;
  background: #ffff;
  border: 2px solid #ffff;
  opacity: 1;
  transition: all 0.3s ease;

  &:hover {
    background-color: $text-color-orange;
  }
  @at-root button#{&} {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none;
  }
  .swiper-pagination-clickable & {
    cursor: pointer;
  }
}
.swiper-pagination-bullet-active {
  opacity: 1;
  transition: all 0.3s ease;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid $text-color-orange;
  transform: scale(1.6);
}

/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  .swiper-pagination-progressbar-fill {
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top;
  }
  .swiper-container-rtl & .swiper-pagination-progressbar-fill {
    transform-origin: right top;
  }
  .swiper-container-horizontal > &,
  .swiper-container-vertical > &.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
  }
  .swiper-container-vertical > &,
  .swiper-container-horizontal > &.swiper-pagination-progressbar-opposite {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.swiper-pagination-lock {
  display: none;
}
