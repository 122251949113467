@import "../abstracts/variables";
@import "../abstracts/mixins";

.obstacle-sports {
  width: 100%;
  height: auto;
  background-color: $back-color-secendary;

  &-minContainer {
    width: 50%;
    margin: 0 auto;
    padding: 3rem 0 0rem 0;

    @include respond(phone) {
      width: 90%;
    }

    &-line {
      width: 5rem;
      height: 0.4rem;
      margin: 0 auto;
      background-color: $text-color-orange;
    }

    & h2 {
      text-align: center;
      font-size: 3.5rem;
      color: $text-color-dark;
      padding: 1rem;
    }
  }

  &-textContainer {
    width: 70%;
    margin: 0rem auto;

    @include respond(big-desktop) {
      width: 60%;
    }

    @include respond(larg-desktop) {
      width: 50%;
    }

    & p {
      text-align: center;
      padding: 3rem 0 3rem 0;
      font-weight: 300;
      font-family: "Poppins", Helvetica, Arial, sans-serif;
      color: #777;
      line-height: 1.5;
      font-size: 1.8rem;
    }
  }
}
