@import "../abstracts/variables";
@import "../abstracts/mixins";

.scroll-Up {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: fadeInDropUp;
  transform-origin: 50% 100%;
  animation-delay: 0.8s;
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  background-color: $text-color-orange;
  position: absolute;
  top: 65%;
  right: 2%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease-in-out;

  @include respond(small-phone) {
    width: 4.5rem;
    height: 4.5rem;
  }

  &:hover {
    cursor: pointer;
    background-color: $text-color-blue_hover;
  }

  & svg {
    color: #fff;
    font-size: 1.7rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
