@import "../abstracts/variables";
@import "../abstracts/mixins";

.searchbox {
  position: absolute;
  width: 22%;
  top: 5%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @include respond(tab-port) {
    width: 40%;
  }

  @media (max-width: 540px) {
    width: 60%;
    top: 3%;
  }

  @media (max-width: 411px) {
    width: 90%;
    top: 5%;
    text-align: center;
  }

  &-form {
    width: 100%;

    & input {
      padding: 0.5rem;
      width: 70%;
      border: none;
      outline: none;
      border-bottom: 1.5px solid $text-color-orange;
      transition: all 0.4s ease;
      background-color: $back-color-secendary;

      @include respond(big-desktop) {
        padding: 1rem 1.5rem 1rem 1.5rem;

        &::-webkit-input-placeholder {
          font-weight: 100;
          font-size: 1.5rem;
        }
      }

      @include respond(phone) {
        padding: 1.2rem 0 1.2rem 3rem;
      }

      &:focus {
        width: 77%;
        background-color: rgba($text-color-orange, 0.7);
        border-radius: 0.5rem;
        border: none;
        outline: none;
        color: #ffff;

        &::-webkit-input-placeholder {
          font-weight: 100;
          color: #ffff;
        }
      }
    }

    & button {
      border: none;
      padding: 0.5rem;
      color: #ffff;
      background-color: $text-color-orange;
      cursor: pointer;
      margin-left: 0.5rem;
      border-radius: 0.5rem;
      transition: all 0.3s ease;

      @include respond(big-desktop) {
        padding: 1rem 1.5rem 1rem 1.5rem;
      }

      @include respond(phone) {
        padding: 1rem 1.5rem 1rem 1.5rem;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: $text-color-blue_hover;
      }
    }
  }
}
