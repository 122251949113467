@import "../abstracts/variables";
@import "../abstracts/animations";
@import "../abstracts/mixins";

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;

  &-bar {
    display: none;
  }

  @include respond(tab-port) {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0 2rem 0;

    &-bar {
      display: block;
      color: $text-color-orange;
      font-size: 4rem;
      margin-left: 2rem;
    }
  }

  @include respond(small-phone) {
    &-bar {
      font-size: 3.2rem;
    }
  }

  &-logo {
    width: 6rem;
    margin-left: 2rem;

    & svg {
      color: $text-color-orange;
      font-size: 4rem;
    }
  }

  .navShow {
    @include respond(tab-port) {
      left: 0% !important;
      transition: all 0.4s ease;
      height: 100vh;
    }
  }
  &-nav {
    width: 70%;
    margin: 0 auto;

    &_closebtn {
      display: none;
    }

    &_dropdown {
      display: none;
    }

    @include respond(tab-port) {
      height: 50vh;
      width: 65%;
      position: absolute;
      background-color: $text-color-dark;
      top: 0;
      left: -70%;
      z-index: 2;
      transition: all 0.4s ease;

      &_dropdown {
        display: block;
        position: absolute;
        width: 10%;
        top: 40%;
        left: 15%;
        transform: translate(-50%, -50%);
      }

      &_closebtn {
        display: block;
        color: $text-color-orange;
        font-size: 2rem;
      }

      &_homeLink {
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: space-between;
      }
    }

    &-ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: normal;
      width: 60%;
      margin: 0 auto;
      padding: 4.5rem 3rem;
      z-index: 3;
      transition: all 0.4s ease;

      @include respond(tab-land) {
        width: 70%;
      }

      @include respond(tab-port) {
        flex-direction: column;
        width: 80%;
        height: 40%;
        align-items: flex-start;
      }

      @include respond(phone) {
        width: 100%;
      }

      @include respond(small-phone) {
        height: 50%;
      }

      & li a {
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        transition: all 0.3s ease;

        @include respond(larg-desktop) {
          font-size: 2rem;
        }

        @include respond(tab-port) {
          font-size: 2.5rem;
        }

        @include respond(phone) {
          font-size: 2rem;
        }

        @include respond(small-phone) {
          font-size: 1.7rem;
        }

        &:hover {
          color: $text-color-orange;
          text-decoration: none;
        }
      }
    }

    &-secondary {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 13%;

      @include respond(tab-port) {
        width: 17%;
      }

      @include respond(tab-port) {
        &_dropdown {
          display: none;
        }
      }
    }
  }
}

.language-dropdown {
  width: 4rem;
  font-size: 1.3rem;
  position: relative;
  cursor: pointer;

  @include respond(larg-desktop) {
    font-size: 1.7rem;
  }

  @include respond(tab-port) {
    position: absolute;
    top: 0;
    left: 2.9rem;
    font-size: 2.2rem;
    width: 6rem;
  }

  @include respond(phone) {
    left: 0;
    font-size: 2rem;
  }

  @include respond(small-phone) {
    top: 5rem;
    left: 0.5rem;
  }

  &-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffff;
  }

  &-list {
    position: absolute;
    background-color: $navbar-color;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    left: 0;
    height: 5rem;
    top: 2rem;
    list-style: none;
    padding: 0;

    @include respond(phone) {
      top: 3rem;
      height: 7rem;
      width: 100%;
    }

    & li {
      transition: all 0.3s;
      color: #ffff;

      &:hover {
        color: $text-color-orange;
      }
    }
  }
}

.stk-nav {
  background-color: $navbar-color;
  transition: all 0.4s ease;

  & .header-nav {
    width: 70%;
    transition: all 0.4s ease;

    & ul {
      padding: 3rem 1.5rem;
      transition: all 0.4s ease;

      @include respond(larg-desktop) {
        padding: 4rem 2rem;
      }
    }
  }
}

.signIn-container {
  cursor: pointer;
  position: relative;

  &-header {
    font-size: 1.3rem;
    color: #ffff;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    @include respond(phone) {
      font-size: 1.3rem;
      margin-right: 2rem;
      width: 10rem;
    }

    @include respond(larg-desktop) {
      font-size: 1.7rem;
    }

    &-user {
      color: $text-color-orange;
      margin-right: 0.3rem;
    }

    & a {
      text-decoration: none;
      color: #ffff;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      justify-content: space-between;
      transition: all 0.3s;

      &:hover {
        color: $text-color-orange;
      }
    }
  }

  &-dropdown {
    position: absolute;
    background-color: $navbar-color;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    left: 0;
    height: auto;
    top: 2rem;
    list-style: none;
    padding: 0.6rem;

    @include respond(phone) {
      width: 60%;
    }

    & a {
      text-decoration: none;
      color: #ffff;
    }

    & li {
      font-size: 1.3rem;
      padding: 0.2rem;
      color: #ffff;

      @include respond(phone) {
        padding: 0.5rem;
        font-size: 1.5rem;
      }

      &:hover {
        text-decoration: underline;
        color: $text-color-orange;
      }
    }
  }
}

.active {
  color: $text-color-orange !important;
}

.rotate-lft {
  transform: rotateZ(180deg);
  margin-left: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.rotate-rght {
  transform: rotateZ(0);
  margin-left: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.dropdown_animated_up {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: fadeInDropUp;
  transform-origin: 50% 100%;
}

.navShowClass {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(#8d8787, 0.37);
    visibility: visible;
    -webkit-backdrop-filter: blur(0.1rem);
    backdrop-filter: blur(0.1rem);
  }
}
