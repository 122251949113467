@mixin respond($breakpoint) {
  @if $breakpoint == small-phone {
    @media (max-width: 20em) {
      @content;
    } // 320px
  }
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    } // 600px
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    } // 900px
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    } // 1200px
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } // 1800px
  }
  @if $breakpoint == larg-desktop {
    @media (min-width: 137.5em) {
      @content;
    } // 2200px
  }
}
