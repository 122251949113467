.alert {
  padding: 1rem;
  border-radius: 0.5rem;
  width: 70%;
  margin: 0 auto;

  & h2 {
    color: #cccc;
    font-weight: 400;
  }
}
