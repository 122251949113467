@import "../abstracts/variables";
@import "../abstracts/mixins";

.single-blog {
  width: 100%;
  height: auto;
  margin: 11rem auto 0 auto;
  background-color: $back-color-secendary;
  padding: 0 0 10rem 0;

  @include respond(phone) {
    margin: 7rem auto 0 auto;
  }

  &_container {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;

    @include respond(big-desktop) {
      width: 70%;
    }

    @include respond(larg-desktop) {
      width: 65%;
    }

    @include respond(tab-land) {
      top: 50%;
      display: block;
    }

    @include respond(tab-port) {
      width: 80%;
    }

    @include respond(phone) {
      width: 100%;
      text-align: center;
    }

    &_small {
      width: 55%;
      padding-top: 8rem;

      @include respond(tab-land) {
        width: 90%;
      }

      @include respond(phone) {
        text-align: start;
        margin: 0 auto;
        padding-top: 5rem;
      }

      &-back {
        cursor: pointer;
        font-size: 2rem;
        margin: 1rem 0 2rem 0rem;
        color: $text-color-orange;
        transition: all 0.3s ease;
        animation-name: moveInRight;
        animation-duration: 1.3s;
        animation-timing-function: ease-out;

        @include respond(big-desktop) {
          font-size: 2.2rem;
        }

        @include respond(larg-desktop) {
          font-size: 2.8rem;
        }

        @include respond(tab-land) {
          font-size: 2.2rem;
        }

        &:hover {
          color: $text-color-blue_hover;
        }
      }

      &-title {
        color: $text-color-orange;
        font-size: 2.4rem;
        padding: 1rem 0 2rem 0rem;
        animation-name: moveInRight;
        animation-duration: 1.3s;
        animation-timing-function: ease-out;

        @include respond(big-desktop) {
          font-size: 2.7rem;
        }
      }

      &-text {
        font-size: 1.6rem;
        padding: 1rem 0 2rem 0rem;
        color: $text-color-md-dark;
        animation-name: moveInRight;
        animation-duration: 1.3s;
        animation-timing-function: ease-out;

        @include respond(big-desktop) {
          font-size: 2rem;
        }
      }

      &-date {
        font-size: 1.3rem;
        padding: 2rem 0 1rem 0rem;
        color: #9c9c9c;
        font-family: sans-serif;
        font-weight: 700;
        display: flex;
        width: 10rem;
        justify-content: space-between;
        animation-name: moveInRight;
        animation-duration: 1.3s;
        animation-timing-function: ease-out;

        & svg {
          font-size: 1.3rem;
          color: $text-color-orange;
        }

        @include respond(big-desktop) {
          font-size: 1.5rem;

          & svg {
            font-size: 1.5rem;
          }
        }
      }
    }

    &-image {
      width: 45rem;
      height: 40rem;
      border-radius: 3rem;
      margin-top: 8rem;
      animation-name: moveInLeft;
      animation-duration: 1.3s;
      animation-timing-function: ease-out;

      @include respond(big-desktop) {
        width: 47rem;
        height: 42rem;
      }

      @include respond(tab-land) {
        width: 60rem;
        height: 50rem;
      }

      @media (max-width: 540px) {
        width: 90%;
        height: 43rem;
      }

      @media (max-width: 411px) {
        height: 35rem;
      }

      @include respond(small-phone) {
        height: 30rem;
      }
    }
  }
}
