@import "../abstracts/_variables.scss";
@import "../abstracts/mixins";

.about {
  width: 100%;
  height: 100vh;
  background-color: $back-color-secendary;

  &-container {
    width: 100%;
    margin: 11.5rem auto 0 auto;
    padding: 0rem 0rem 8rem 0;
    background-color: $back-color-secendary;

    @include respond(tab-port) {
      margin: 7rem auto 0 auto;
    }

    &-top {
      width: 75%;
      position: relative;
      margin: 0 auto;
      height: auto;

      @include respond(phone) {
        width: 90%;
      }

      &_img {
        height: 55rem;
        width: 100%;
        vertical-align: middle;

        @include respond(big-desktop) {
          height: 75rem;
        }

        @include respond(tab-land) {
          height: 35vh;
        }
      }
    }

    &-middle {
      position: relative;
      width: 100%;

      &-container {
        margin: 0 auto;
        width: 70%;

        @include respond(phone) {
          width: 90%;
        }

        &_line {
          width: 6rem;
          height: 0.4rem;
          background-color: $text-color-orange;
          margin: 7rem 0rem 0rem 0rem;

          @include respond(phone) {
            width: 80%;
            margin: 7rem auto 0 auto;
          }
        }
        & h2 {
          padding: 3rem 0 3rem 0rem;
          color: $text-color-dark;
          font-size: 5rem;

          @include respond(phone) {
            font-size: 3.5rem;
            text-align: center;
          }
        }

        & p {
          padding: 1rem 0 2rem 0rem;
          font-weight: 300;
          font-family: "Poppins", Helvetica, Arial, sans-serif;
          color: #777;
          line-height: 1.5;
          font-size: 1.8rem;

          @include respond(phone) {
            width: 100%;
          }
        }
      }
    }
  }
}
