@font-face {
  font-family: "Larsseit";
  src: url("../../fonts/Larsseit.otf") format("truetype");
}

@font-face {
  font-family: "Arial Caps";
  src: url("../../fonts/Arial-Caps.ttf") format("truetype");
}

@font-face {
  font-family: "Arial Regular";
  src: url("../../fonts/Arial-Regular.ttf") format("truetype");
}

.geoFont-Nav {
  font-family: "Arial Caps";
}

.geoFont-Login {
  font-family: "Arial Regular";
}

.engFont-Nav {
  font-family: "Larsseit";
  font-weight: normal;
}
