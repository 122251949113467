$text-color-white: #ffff;
// $text-color-orange: #009092;
$text-color-orange: #0d828f;
$input-background: #a1dedf;
$text-color-orange_hover: #252525;
$text-color-blue_hover: #035c5e;
$text-color-dark: #333;
$text-color-md-dark: #777;
$navbar-color: #252525;
$back-color-secendary: #f3f3f3;
$box-shadow: 0px 0px 12px 3px rgba($text-color-orange, 0.3);
