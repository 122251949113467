@import "../abstracts//variables";
@import "../abstracts/animations";
@import "../abstracts/mixins";

.no-result-height {
  height: 100vh !important;
}
.event {
  width: 100%;
  height: auto;
  margin: 11rem auto 0 auto;
  position: relative;
  background-color: $back-color-secendary;

  @include respond(tab-port) {
    margin: 7rem auto 0 auto;
  }

  &_line {
    width: 6rem;
    height: 0.4rem;
    background-color: $text-color-orange;
    position: absolute;
    top: 4%;
    left: 8.7%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    @include respond(big-desktop) {
      width: 8rem;
      left: 8%;
    }

    @include respond(larg-desktop) {
      left: 7%;
    }

    @include respond(tab-port) {
      left: 9%;
      top: 7%;
    }

    @include respond(phone) {
      display: none;
    }
  }

  & h1 {
    font-size: 4.5rem;
    color: $navbar-color;
    font-weight: 700;
    padding: 5rem 0 0rem 8.5rem;

    @include respond(tab-land) {
      padding: 5rem 0 0rem 6.5rem;
    }

    @include respond(tab-port) {
      padding: 8rem 0 0rem 5.5rem;
    }

    @media (max-width: 540px) {
      text-align: center;
      padding: 15rem 0 0 0;
    }
  }

  & a {
    text-decoration: none;

    &:hover {
      cursor: default;
    }
  }

  &-container {
    display: flex;
    width: 95%;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0rem 0rem 8rem 0;

    &-items {
      height: auto;
      width: 30%;
      margin: 4rem 0 4rem 0rem;
      position: relative;
      border-radius: 0.5rem;

      @media (max-width: 540px) {
        width: 75%;
        margin: 3rem auto;
      }

      @media (max-width: 411px) {
        width: 90%;
        margin: 5rem auto 0rem auto;
      }

      &-title {
        position: absolute;
        text-align: center;
        width: 90%;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        color: $back-color-secendary;
        text-transform: uppercase;
        font-weight: normal;
        letter-spacing: 0.05rem;
        font-size: 1.7rem;

        @include respond(big-desktop) {
          font-size: 2rem;
        }

        @include respond(phone) {
          font-size: 2rem;
        }
      }

      &-text {
        position: absolute;
        width: 65%;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        font-weight: normal;
        color: $back-color-secendary;
        text-align: center;
        font-size: 1.3rem;
        letter-spacing: 0.05rem;

        @include respond(big-desktop) {
          font-size: 1.5rem;
        }

        @include respond(tab-land) {
          top: 50%;
          font-weight: 100 !important;
        }

        @include respond(tab-port) {
          top: 45%;
          width: 90%;
        }

        @media (max-width: 540px) {
          top: 40%;
          width: 65%;
          font-size: 1.7rem;
        }

        @media (max-width: 411px) {
          font-size: 1.3rem;
        }
      }

      &-image {
        width: 100%;
        height: 30rem;
        border-radius: 0.5rem;
        filter: brightness(0.4);

        @include respond(big-desktop) {
          height: 35rem;
        }

        @include respond(larg-desktop) {
          height: 40rem;
        }

        @include respond(tab-port) {
          height: 25rem;
        }

        @media (max-width: 540px) {
          height: 40rem;
        }

        @media (max-width: 511px) {
          height: 35rem;
        }

        @include respond(small-phone) {
          height: 30rem;
        }
      }

      &-detail {
        position: absolute;
        width: 100%;
        height: 0%;
        left: 0;
        bottom: 0;
        background-color: rgba($back-color-secendary, 0.7);
        transition: all 0.8s ease;

        &-btn {
          display: none;
        }

        &-date {
          display: none;
        }

        @include respond(tab-land) {
          height: 34%;

          &-btn {
            position: absolute;
            top: 5.5rem;
            left: 2rem;
            color: $text-color-orange;
            font-size: 1.5rem;
            display: block;
            border: none;
            animation-name: eventDetalUp;
            animation-duration: 1.3s;
            animation-timing-function: ease-out;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: all 0.4s ease;
            background-color: transparent;

            & svg {
              margin-left: 0.7rem;
              transition: all 0.4s ease;
            }
          }

          &-date {
            position: absolute;
            top: 2.5rem;
            left: 2rem;
            color: $text-color-orange;
            font-size: 1.3rem;
            display: block;
            animation-name: eventDetalUp;
            animation-duration: 1.3s;
            animation-timing-function: ease-out;
          }
        }

        @media (max-width: 540px) {
          &-btn {
            top: 7rem;
            font-size: 1.7rem;
          }
        }

        @include respond(small-phone) {
          &-btn {
            top: 6rem;
          }
        }
      }

      &:hover .event-container-items-detail {
        height: 35%;

        &-date {
          position: absolute;
          top: 2.5rem;
          left: 2rem;
          color: $text-color-orange;
          font-size: 1.3rem;
          display: block;
          animation-name: eventDetalUp;
          animation-duration: 1.2s;
          animation-timing-function: ease-out;

          @include respond(big-desktop) {
            font-size: 1.5rem;
          }
        }

        &-btn {
          position: absolute;
          top: 6rem;
          left: 2rem;
          color: $text-color-orange;
          font-size: 1.5rem;
          display: block;
          border: none;
          animation-name: eventDetalUp;
          animation-duration: 1.3s;
          animation-timing-function: ease-out;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: all 0.4s ease;
          background-color: transparent;

          @include respond(big-desktop) {
            font-size: 1.7rem;
          }

          & svg {
            margin-left: 0.5rem;
            transition: all 0.4s ease;
          }

          &:hover {
            cursor: pointer;
            color: $text-color-blue_hover;

            & svg {
              margin-left: 1rem;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.event_List {
  width: 75%;
  margin: 11.5rem auto;

  @include respond(tab-port) {
    width: 90%;
  }
  @include respond(phone) {
    width: 100%;
  }

  & h2 {
    text-align: center;
    font-size: 2rem;
    color: $navbar-color;
  }

  &-container {
    width: 90%;
    margin: 0 auto;
    overflow-y: scroll;
    height: 50vh;

    @include respond(larg-desktop) {
      width: 70%;
    }

    @include respond(phone) {
      width: 98%;
    }

    &::-webkit-scrollbar {
      width: 0.3rem;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $text-color-orange;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $text-color-blue_hover;
      cursor: pointer;
    }

    &-createBtn {
      font-size: 1.5rem;
      padding: 0.7rem;
      float: right;
      margin-bottom: 1.5rem;
      width: 12rem;
      background-color: $text-color-orange;
      border-radius: 0.5rem;
      transition: background-color 0.3s;

      @include respond(phone) {
        font-size: 1.3rem;
        padding: 1rem;
      }

      &:hover {
        background-color: $text-color-orange_hover;
        cursor: pointer;
      }

      & a {
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffff;
      }
    }

    &-table {
      width: 100%;
      font-size: 1.3rem;
      border: 1px solid black;

      @include respond(phone) {
        font-size: 1.1rem;
      }

      & th {
        text-align: start;
        border-bottom: 1px solid black;
        border-right: 1px solid black;
        padding: 0.5rem;
        width: 7rem;
      }

      & td {
        border-bottom: 1px solid black;
        padding: 0.5rem;

        & a {
          color: black;
        }
      }
    }
  }

  &-find {
    width: 90%;
    margin: 5rem auto;

    & form {
      width: 50%;
      margin: 2rem auto;
      text-align: center;

      @include respond(tab-land) {
        width: 90%;
      }

      & input {
        padding: 0.5rem;
        outline: none;
        overflow: hidden;
        border: none;
        border-bottom: 1px solid $text-color-orange;
      }

      & button {
        padding: 0.6rem;
        color: #fff;
        margin-left: 0.5rem;
        cursor: pointer;
        border-radius: 0.5rem;
        background-color: $text-color-orange;
        border: none;
        transition: all 0.3s;

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: $text-color-orange_hover;
        }
      }
    }
  }
}

.form_event {
  width: 90%;
  margin: 11.5rem auto;

  & svg {
    font-size: 2rem;
    color: $text-color-orange;
  }

  & h1 {
    text-align: center;
    padding: 2rem;
  }

  & a {
    font-size: 1.5rem;
  }

  &-container {
    width: 60%;
    margin: 0 auto;
    background-color: #ffff;

    @include respond(phone) {
      width: 95%;
    }

    &-back {
      font-size: 1.5rem;
      color: $navbar-color;
      transition: all 0.3s;
      margin: 1rem;

      &:hover {
        color: $text-color-orange;
      }
    }

    &-form {
      width: 65%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      font-size: 1.3rem;

      @include respond(tab-land) {
        width: 85%;
      }

      @include respond(tab-port) {
        width: 100%;
      }

      & label {
        padding: 1rem;
      }

      & input {
        padding: 1rem;
        width: 100%;
        padding: 1rem;
        border-radius: 0.5rem;
        border: none;
        background-color: $text-color-orange;
        transition: all 0.4s ease;
        outline: none;

        &::-webkit-input-placeholder {
          font-weight: 100;
          color: #ffff;
        }

        &:focus {
          background-color: rgba($text-color-orange, 0.7);
          border-radius: 0.5rem;
          color: #ffff;
          box-shadow: none;
        }
      }

      & textarea {
        padding: 1rem;
        width: 100%;
        padding: 1rem;
        border-radius: 0.5rem;
        border: none;
        background-color: $text-color-orange;
        transition: all 0.4s ease;
        outline: none;

        &::-webkit-input-placeholder {
          font-weight: 100;
          color: #ffff;
        }

        &:focus {
          background-color: rgba($text-color-orange, 0.7);
          border-radius: 0.5rem;
          color: #ffff;
        }
      }

      & button {
        padding: 1rem;
        width: 50%;
        border-radius: 0.5rem;
        border: none;
        background-color: $text-color-orange;
        color: #fff;
        border: none;
        margin: 2rem 0 2rem 0;
        cursor: pointer;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}
