@import "../abstracts/variables";
@import "../abstracts/mixins";

.footer {
  width: 100%;
  height: auto;
  background-color: $navbar-color;
  position: relative;

  &-container {
    width: 95%;
    margin: 0 auto;
    height: 40vh;

    @include respond(tab-land) {
      height: 28vh;
    }

    @include respond(phone) {
      height: 35vh;
    }

    &-list {
      display: flex;
      justify-content: space-between;
      font-size: 1.3rem;
      width: 35%;
      text-transform: uppercase;
      align-items: center;
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);

      @include respond(big-desktop) {
        top: 20%;
        font-size: 1.5rem;
      }

      @include respond(tab-port) {
        top: 15%;
        width: 50%;
      }

      @include respond(phone) {
        width: 90%;
      }

      & li {
        padding: 0.5rem;

        & a {
          color: $text-color-orange;
          text-decoration: none;
          font-weight: 500;
          transition: 0.3s;

          &:hover {
            color: #ffff;
          }
        }
      }
    }

    &-socials {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 20%;
      position: absolute;
      top: 55%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      @include respond(big-desktop) {
        top: 50%;
      }

      @include respond(tab-land) {
        top: 45%;
      }

      @include respond(phone) {
        width: 50%;
        top: 40%;
      }

      &-link {
        display: inline;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        box-shadow: $box-shadow;
        position: relative;

        &:hover > svg {
          color: #ffff;
        }

        & svg {
          position: absolute;
          font-size: 1.5rem;
          top: 50%;
          left: 50%;
          color: $text-color-orange;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          transition: all 0.3s;

          &:hover {
            color: #ffff;
          }
        }
      }

      &-text {
        position: absolute;
        color: #ffff;
        font-size: 1.1rem;
        letter-spacing: 0.1rem;
        top: 77%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        @include respond(big-desktop) {
          top: 70%;
          font-size: 1.3rem;
        }

        @include respond(tab-land) {
          top: 65%;
        }

        @include respond(phone) {
          width: 98%;
          text-align: center;
          top: 60%;
          font-size: 1.1rem;
        }
      }

      &-shmagi {
        position: absolute;
        color: #ffff;
        font-size: 1.1rem;
        letter-spacing: 0.1rem;
        top: 90%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        @include respond(big-desktop) {
          top: 85%;
          font-size: 1.3rem;
        }

        @include respond(tab-land) {
          top: 80%;
          font-size: 1.2rem;
        }

        @include respond(phone) {
          width: 90%;
          text-align: center;
        }

        & a {
          text-decoration: none;
          color: $text-color-orange;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
