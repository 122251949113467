@import "../abstracts/variables";
@import "../abstracts/animations";
@import "../abstracts/mixins";

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  height: 100%;
  width: 100%;
  /* Fix of Webkit flickering */
  z-index: 1;
}
.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}
.swiper-container-multirow-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column;
}
.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-container-pointer-events {
  touch-action: pan-y;
  &.swiper-container-vertical {
    touch-action: pan-x;
  }
}
.swiper-slide {
  flex-shrink: 0;
  width: 100% !important;
  height: 100%;
  position: relative;
  transition-property: transform;

  & img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &-container {
    position: absolute;
    top: 30%;
    left: 30%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    color: #ffff;
    z-index: 3;
    width: 40%;
    height: 40%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    animation-name: fadeIn;
    transform-origin: 50% 100%;
    opacity: 1;

    @include respond(tab-land) {
      height: 25%;
      width: 45%;
    }

    @include respond(tab-port) {
      height: 30%;
    }

    @include respond(phone) {
      width: 100%;
      height: 25%;
      left: 0%;
    }

    & span {
      width: 5rem;
      height: 0.4rem;
      background-color: $text-color-orange;
    }

    &_title {
      text-transform: uppercase;
      font-size: 2.4rem;
      font-weight: 400;
      letter-spacing: 0.1rem;

      @include respond(phone) {
        font-size: 1.5rem;
      }
    }

    &_text {
      font-size: 4rem;
      font-weight: 700;
      text-align: center;
      letter-spacing: 0.1rem;

      @include respond(tab-land) {
        font-size: 3rem;
      }

      @include respond(tab-port) {
        font-size: 2.5rem;
      }

      @include respond(phone) {
        font-size: 2rem;
      }

      @include respond(small-phone) {
        font-size: 1.7rem;
      }
    }
    &_btn {
      padding: 1.1rem 3.5rem 1rem;
      background-color: $text-color-orange;
      font-size: 1.4rem;
      color: #ffff;
      line-height: 2.2rem;
      transition: all 0.5s ease;
      text-decoration: none;

      &:hover {
        background-color: $navbar-color;
        cursor: pointer;
      }
    }
  }
}
.swiper-slide-invisible-blank {
  visibility: hidden;
}
/* Auto Height */
.swiper-container-autoheight {
  &,
  .swiper-slide {
    height: auto;
  }

  .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }
}

/* 3D Effects */
.swiper-container-3d {
  perspective: 1200px;
  .swiper-wrapper,
  .swiper-slide,
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right,
  .swiper-slide-shadow-top,
  .swiper-slide-shadow-bottom,
  .swiper-cube-shadow {
    transform-style: preserve-3d;
  }
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right,
  .swiper-slide-shadow-top,
  .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
  }
  .swiper-slide-shadow-left {
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }
  .swiper-slide-shadow-right {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }
  .swiper-slide-shadow-top {
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }
  .swiper-slide-shadow-bottom {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }
}

/* CSS Mode */
.swiper-container-css-mode {
  > .swiper-wrapper {
    overflow: auto;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  > .swiper-wrapper > .swiper-slide {
    scroll-snap-align: start start;
  }
}
.swiper-container-horizontal.swiper-container-css-mode {
  > .swiper-wrapper {
    scroll-snap-type: x mandatory;
  }
}
.swiper-container-vertical.swiper-container-css-mode {
  > .swiper-wrapper {
    scroll-snap-type: y mandatory;
  }
}

.animated {
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-delay: 1s;

  & .swiper-slide-container_title {
    animation-name: fadeIn;
    transform-origin: 50% 100%;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-delay: 1s;
  }

  & .swiper-slide-container_text {
    animation-name: fadeIn;
    transform-origin: 50% 100%;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-delay: 1s;
  }
  & .swipre-slide-container_btn {
    animation-name: fadeIn;
    transform-origin: 50% 100%;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-delay: 1s;
  }
}
