@keyframes loading-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(7rem) scale(1);
    opacity: 0;
  }
  to {
    transform: translateY(0) scale(1.3);
    opacity: 1;
  }
}

@keyframes fadeInDropUp {
  from {
    transform: translateY(1rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes eventDetalUp {
  0% {
    transform: translateY(2rem);
    opacity: 0;
  }
  50% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scaleUp {
  0% {
    opacity: 0;
    transform: translateZ(-20);
  }
  80% {
    opacity: 0.5;
    transform: scale(1.1) translateZ(10);
  }
  0% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-30rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(30rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
