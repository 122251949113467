@import "../abstracts/variables";
@import "../abstracts/mixins";

.userLogin {
  width: 35%;
  height: auto;
  margin: 12rem auto;
  position: relative;

  @include respond(big-desktop) {
    width: 30%;
  }

  @include respond(tab-land) {
    height: 100%;
    margin: 12rem auto 5rem auto;
    height: 100%;
  }

  @include respond(phone) {
    width: 90%;
  }

  &-minContainer {
    width: 100%;
    padding: 2rem 0 0 0;

    &_line {
      width: 6rem;
      height: 0.4rem;
      background-color: $text-color-orange;
    }

    & h1 {
      font-size: 3rem;
      color: $navbar-color;
      padding: 2rem 0 1rem 0rem;
    }
  }

  &-form {
    width: 100%;
    height: 17rem;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;

    & input {
      width: 100%;
      padding: 1rem;
      border-radius: 0.5rem;
      border: none;
      background-color: $text-color-orange;
      transition: all 0.4s ease;
      outline: none;

      &::-webkit-input-placeholder {
        font-weight: 100;
        color: #ffff;
      }

      &:focus {
        background-color: rgba($text-color-orange, 0.7) !important;
        border-radius: 0.5rem;
        color: #ffff;
        box-shadow: none;
      }
    }

    & label {
      font-size: 1.3rem;
      padding: 1.5rem 0rem 1.5rem 0rem;
    }

    & button {
      width: 32%;
      padding: 1rem 0rem 1rem 0rem;
      margin-top: 2rem;
      cursor: pointer;
      border: none;
      background-color: $text-color-orange;
      color: #fff;
      border-radius: 0.5rem;
      transition: 0.4s ease;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: $text-color-blue_hover;
      }
    }
  }

  &-info {
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
    margin: 5rem auto;
    padding: 2rem;

    @include respond(phone) {
      margin: 7rem auto 0 auto;
    }

    & a {
      margin-left: 0.5rem;
      text-decoration: none;
      color: $text-color-orange;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.userRegister {
  width: 35%;
  height: auto;
  margin: 12rem auto;
  position: relative;

  @include respond(big-desktop) {
    width: 30%;
  }

  @include respond(phone) {
    width: 90%;
  }

  &-minContainer {
    width: 100%;
    padding: 2rem 0 0 0;

    &_line {
      width: 6rem;
      height: 0.4rem;
      background-color: $text-color-orange;
    }

    & h1 {
      font-size: 3rem;
      color: $navbar-color;
      padding: 2rem 0 1rem 0rem;
    }
  }

  &-form {
    width: 100%;
    height: 30rem;
    margin: 0rem auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & input {
      width: 100%;
      padding: 1rem;
      border-radius: 0.5rem;
      border: none;
      background-color: $text-color-orange;
      transition: all 0.4s ease;
      outline: none;

      &::-webkit-input-placeholder {
        font-weight: 100;
        color: #ffff;
      }

      &:focus {
        background-color: rgba($text-color-orange, 0.7);
        border-radius: 0.5rem;
        color: #ffff;
        box-shadow: none;
      }
    }

    & label {
      font-size: 1.3rem;
      padding: 1.5rem 0rem 1.5rem 0rem;
    }

    & button {
      width: 32%;
      padding: 1rem 0rem 1rem 0rem;
      margin-top: 2rem;
      cursor: pointer;
      border: none;
      background-color: $text-color-orange;
      color: #fff;
      border-radius: 0.5rem;
      transition: 0.4s ease;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: $text-color-blue_hover;
      }
    }
  }

  &-info {
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
    margin: 8rem auto 0 auto;
    padding: 2rem;

    @include respond(tab-land) {
      margin: 12rem auto 0 auto;
    }

    & a {
      margin-left: 0.5rem;
      text-decoration: none;
      color: $text-color-orange;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.List {
  width: 60%;
  margin: 11.5rem auto;

  @include respond(tab-land) {
    width: 80%;
  }

  @include respond(phone) {
    width: 100%;
  }

  & h2 {
    text-align: center;
    font-size: 2rem;
    color: $navbar-color;
  }

  &-container {
    width: 90%;
    margin: 2rem auto;
    overflow-y: scroll;
    height: 30vh;

    @include respond(phone) {
      width: 95%;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &-table {
      width: 100%;
      font-size: 1.3rem;
      border: 1px solid black;

      @include respond(phone) {
        font-size: 1.1rem;
      }

      & th {
        text-align: start;
        border-bottom: 1px solid black;
        border-right: 1px solid black;
        padding: 0.5rem;
      }

      & td {
        border-bottom: 1px solid black;
        padding: 0.5rem;

        & a {
          color: black;
        }

        & svg {
          cursor: pointer;
        }
      }
    }
  }

  &-find {
    width: 90%;
    margin: 5rem auto;

    & form {
      width: 50%;
      margin: 2rem auto;
      text-align: center;

      @include respond(phone) {
        width: 100%;
      }

      & input {
        padding: 0.5rem;
        outline: none;
        overflow: hidden;
        border: none;
        border-bottom: 1px solid $text-color-orange;
      }

      & button {
        padding: 0.6rem;
        color: #fff;
        margin-left: 0.5rem;
        cursor: pointer;
        border-radius: 0.5rem;
        background-color: $text-color-orange;
        border: none;
        transition: all 0.3s;

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: $text-color-orange_hover;
        }
      }
    }
  }
}

.userDetail {
  width: 35%;
  background-color: #fff;
  margin: 12rem auto;
  position: relative;

  @include respond(big-desktop) {
    width: 30%;
  }

  @include respond(tab-land) {
    width: 50%;
  }

  @include respond(phone) {
    width: 90%;
  }

  & svg {
    font-size: 2rem;
  }

  &-line {
    width: 6rem;
    height: 0.4rem;
    background-color: $text-color-orange;
    position: absolute;
    top: 5rem;
    left: 0;
  }

  & h1 {
    font-size: 3rem;
    color: $navbar-color;
    padding: 6rem 0 0rem 0rem;
  }

  &-goBack {
    color: $text-color-orange;
    overflow: hidden;
    border: none;
    font-size: 2rem;
    transition: all 0.3s ease;
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;

    &:hover {
      cursor: pointer;
      color: $text-color-blue_hover;
    }
  }

  &-container {
    width: 100%;
    margin: 2rem auto;

    &-form {
      width: 100%;
      display: flex;
      flex-direction: column;
      font-size: 1.3rem;

      & input {
        width: 100%;
        padding: 1rem;
        border-radius: 0.5rem;
        border: none;
        background-color: $text-color-orange;
        transition: all 0.4s ease;
        outline: none;

        &::-webkit-input-placeholder {
          font-weight: 100;
          color: #ffff;
        }

        &:focus {
          background-color: rgba($text-color-orange, 0.7);
          border-radius: 0.5rem;
          color: #ffff;
          box-shadow: none;
        }
      }

      & label {
        font-size: 1.3rem;
        padding: 1.2rem 0rem 1.2rem 0rem;
      }

      & button {
        width: 32%;
        padding: 1rem 0rem 1rem 0rem;
        margin-top: 2rem;
        cursor: pointer;
        border: none;
        background-color: $text-color-orange;
        color: #fff;
        border-radius: 0.5rem;
        transition: 0.4s ease;

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: $text-color-blue_hover;
        }
      }
    }
  }
}
