@import '../abstracts/animations';
@import '../abstracts/_variables.scss';

.loader {
    text-align: center;
    padding: 6rem;
       
       &-spinner {
           font-size: 4rem;
           animation-name: loading-spinner;
           animation-duration: 1s;
           animation-iteration-count: infinite;
           animation-timing-function: linear;
           color: $text-color-orange;
       }
}