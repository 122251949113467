@import "../abstracts/variables";
@import "../abstracts/animations";
@import "../abstracts/mixins";

.scale-up {
  animation-name: scaleUp;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
}

.blogs {
  width: 100%;
  height: auto;
  margin: 11rem auto 0 auto;
  background-color: $back-color-secendary;
  position: relative;

  @include respond(tab-port) {
    margin: 8rem auto 0 auto;
  }

  &_line {
    width: 6rem;
    height: 0.4rem;
    background-color: $text-color-orange;
    position: absolute;
    top: 3%;
    left: 8.7%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    @include respond(big-desktop) {
      width: 7.5rem;
      height: 0.5rem;
    }

    @include respond(tab-land) {
      left: 10.5%;
    }

    @include respond(tab-port) {
      left: 12.5%;
    }

    @include respond(phone) {
      width: 7.5rem;
      left: 20%;
      top: 1%;
    }

    @include respond(small-phone) {
      left: 21.5%;
    }
  }

  & h1 {
    font-size: 4.5rem;
    color: $navbar-color;
    font-weight: 700;
    padding: 5rem 0 2rem 8.5rem;

    @include respond(big-desktop) {
      padding: 5rem 0 2rem 10rem;
    }

    @include respond(larg-desktop) {
      padding: 5rem 0 2rem 12rem;
    }

    @include respond(tab-land) {
      padding: 6rem 0 0rem 9rem;
    }

    @include respond(phone) {
      padding: 5rem 0 0 5rem;
    }
  }

  &-container {
    display: flex;
    width: 95%;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0rem 0rem 8rem 0;

    &-items {
      width: 30%;
      height: auto;
      border-radius: 3rem;
      background-color: #ffff;
      margin: 4rem 0 4rem 0rem;
      position: relative;
      transition: all 0.3s ease-in-out;
      overflow: hidden;

      @media (max-width: 540px) {
        width: 75%;
        margin: 3rem auto;
      }

      @media (max-width: 411px) {
        width: 90%;
        margin: 5rem auto 0rem auto;
      }

      &:hover {
        transform: scale(1.05);
        overflow: hidden;
      }

      &-title {
        color: $text-color-orange;
        font-size: 2.4rem;
        padding: 0rem 0rem 1rem 2rem;

        @include respond(tab-land) {
          font-size: 2rem;
        }
      }

      &-image {
        width: 100%;
        height: 28rem;
        overflow: hidden;

        @include respond(big-desktop) {
          height: 30rem;
        }

        @include respond(larg-desktop) {
          height: 33rem;
        }

        @include respond(tab-land) {
          height: 20rem;
        }

        @include respond(tab-port) {
          height: 22rem;
        }
        @media (max-width: 540px) {
          height: 40rem;
        }

        @media (max-width: 411px) {
          height: 25rem;
        }

        @include respond(small-phone) {
          height: 22rem;
        }
      }

      &-text {
        color: $text-color-md-dark;
        font-size: 1.5rem;
        font-weight: 400;
        padding: 1.5rem 0rem 2rem 2rem;
        width: 95%;

        @include respond(tab-land) {
          font-size: 1.3rem;
        }
      }

      &-date {
        font-size: 1.3rem;
        padding: 2rem 0 1rem 2rem;
        color: #9c9c9c;
        font-family: sans-serif;
        font-weight: 700;
        display: flex;
        width: 11rem;
        justify-content: space-between;
        align-items: center;

        & svg {
          font-size: 1.3rem;
          color: $text-color-orange;
        }
      }

      & a {
        text-decoration: none;
      }
    }
  }
}

.form_blog {
  width: 90%;
  margin: 11.5rem auto;

  & svg {
    font-size: 2rem;
    color: $text-color-orange;
  }

  & h1 {
    text-align: center;
    padding: 2rem;
  }

  & a {
    font-size: 1.5rem;
  }

  &-container {
    width: 60%;
    margin: 0 auto;
    background-color: #ffff;

    @include respond(phone) {
      width: 95%;
    }

    &-back {
      font-size: 1.5rem;
      color: $navbar-color;
      transition: all 0.3s;
      margin: 1rem;

      &:hover {
        color: $text-color-orange;
      }
    }

    &-form {
      width: 65%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      font-size: 1.3rem;

      @include respond(tab-land) {
        width: 85%;
      }

      @include respond(tab-port) {
        width: 100%;
      }

      & label {
        padding: 1rem;
      }

      & input {
        padding: 1rem;
        width: 100%;
        padding: 1rem;
        border-radius: 0.5rem;
        border: none;
        background-color: $text-color-orange;
        transition: all 0.4s ease;
        outline: none;

        &::-webkit-input-placeholder {
          font-weight: 100;
          color: #ffff;
        }

        &:focus {
          background-color: rgba($text-color-orange, 0.7);
          border-radius: 0.5rem;
          color: #ffff;
          box-shadow: none;
        }
      }

      & textarea {
        padding: 1rem;
        width: 100%;
        padding: 1rem;
        border-radius: 0.5rem;
        border: none;
        background-color: $text-color-orange;
        transition: all 0.4s ease;
        outline: none;

        &::-webkit-input-placeholder {
          font-weight: 100;
          color: #ffff;
        }

        &:focus {
          background-color: rgba($text-color-orange, 0.7);
          border-radius: 0.5rem;
          color: #ffff;
        }
      }

      & button {
        padding: 1rem;
        width: 50%;
        border-radius: 0.5rem;
        border: none;
        background-color: $text-color-orange;
        color: #fff;
        border: none;
        margin: 2rem 0 2rem 0;
        cursor: pointer;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

.blog_List {
  width: 65%;
  margin: 11.5rem auto;

  @include respond(tab-land) {
    width: 70%;
  }

  @include respond(tab-port) {
    width: 85%;
  }

  @include respond(phone) {
    width: 100%;
  }

  & h2 {
    text-align: center;
    font-size: 3rem;
    color: $navbar-color;
  }

  &-container {
    width: 90%;
    margin: 0 auto;
    overflow-y: scroll;
    height: 50vh;

    &::-webkit-scrollbar {
      width: 0.3rem;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $text-color-orange;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $text-color-blue_hover;
      cursor: pointer;
    }

    &-createBtn {
      font-size: 1.5rem;
      padding: 0.7rem;
      float: right;
      margin-bottom: 1.5rem;
      width: 11rem;
      background-color: $text-color-orange;
      border-radius: 0.5rem;
      transition: background-color 0.3s;

      @include respond(phone) {
        font-size: 1.3rem;
        padding: 1rem;
      }

      &:hover {
        background-color: $text-color-orange_hover;
        cursor: pointer;
      }

      & a {
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffff;
      }
    }

    &-table {
      width: 100%;
      font-size: 1.3rem;
      border: 1px solid black;

      @include respond(phone) {
        font-size: 1.1rem;
      }

      & th {
        text-align: start;
        border-bottom: 1px solid black;
        border-right: 1px solid black;
        padding: 0.5rem;
        width: 10rem;
      }

      & td {
        border-bottom: 1px solid black;
        padding: 0.5rem;

        & a {
          color: black;
        }
      }
    }
  }

  &-find {
    width: 90%;
    margin: 5rem auto;

    & form {
      width: 50%;
      margin: 2rem auto;
      text-align: center;

      @include respond(tab-land) {
        width: 90%;
      }

      & input {
        padding: 0.5rem;
        outline: none;
        overflow: hidden;
        border: none;
        border-bottom: 1px solid $text-color-orange;
      }

      & button {
        padding: 0.6rem;
        color: #fff;
        margin-left: 0.5rem;
        cursor: pointer;
        border-radius: 0.5rem;
        background-color: $text-color-orange;
        border: none;
        transition: all 0.3s;

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: $text-color-orange_hover;
        }
      }
    }
  }
}
